<template>
    <el-cascader
        filterable
        clearable
        @change="$emit('cascaderChange')"
        :options="nodes"
        placeholder="Hae kirjoittamalla"
        :filter-method="filter"
        size="small"
        v-model="model"
        style="width: 100%" />
</template>


<script setup lang="ts">
import { KalustepakettiResponse } from '@/api/models/kalustepaketti-response';
import { useKalustepaketit } from '@/methods/kalustepaketitComposables';
import { setNodes, useNimikeKategoria, filter } from '@/methods/nimikkeetComposables';
import { CascaderOption } from 'element-plus';
import { ref, toRef, watch, watchEffect } from 'vue';

const props = defineProps({
    kalustepaketit: {
        type: Boolean,
        default: false
    },
    hanke: {
        type: Number,
        required: false
    },
})
defineEmits(['cascaderChange'])
const model = defineModel({
    type: Array,
    required: false
})

const { kalustepaketit } = useKalustepaketit(toRef(props.hanke))
const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
const nodes = ref<CascaderOption[]>([] as CascaderOption[])

watch(nimikeKategoriat, (value) => {
    if(value && value.length > 0) {
        nodes.value = setNodes(nimikeKategoriat.value ?? [])
    }
},{ immediate: true })
watch(kalustepaketit, (value) => {
    if(!props.kalustepaketit) {
        return
    }
    if(value && value.length > 0) {
        const k =  value.map((k: KalustepakettiResponse) => {
            return { 
                    value: k.id,
                    label: k.nimi,
                }
        })

        nodes.value.push({
            value: "kalustepaketit",
            label: "Kalustepaketit",
            children: k,
        })
    }
},{ immediate: true })
watchEffect(() => {
    if(!props.kalustepaketit) {
        return
    }
    if(nodes.value && nodes.value.length > 0 && kalustepaketit.value) {
        nodes.value = nodes.value.sort((a, b) => {
            if(a.label && b.label) {
                return a.label.localeCompare(b.label) 
            } else {
                return 0
            }
        })
    }
})
</script>