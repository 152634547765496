<template>
    <h3 v-if="showHeading(1)">Suunnittelutiedot</h3>
    <el-form :model="suunnittelutiedot" @submit.prevent ref="form" :inline="false" size="small">
        <el-row :gutter="20">
            <template v-if="isSelected('nimike')">
                <el-col :xl="14" :span="24" >
                    <el-form-item>
                        <el-space>
                            <ComSearchButton @click="showHaku = !showHaku"></ComSearchButton>
                            <ComNimikeCascader 
                                v-if="showHaku" 
                                @cascaderChange="hakuSelectionChange" 
                                v-model="hakuModel">
                            </ComNimikeCascader>
                        </el-space>
                    </el-form-item>
                    <el-form-item>
                        <el-cascader-panel
                        :options="nodes"
                        :props="{
                            disabled: false,
                            multiple: true,
                        }"
                        size="small"
                        v-model="selectedNodes"
                        style="width: 100%"
                        >
                            <template #default="{ node, data }">
                                <span v-if="!node.isLeaf || !node.checked">{{ data.label }}</span>
                                <span v-else-if="node.isLeaf && node.checked">
                                    <el-link :icon="Edit" @click="cascaderFocus(data.value)">
                                        <div style="overflow: hidden; text-overflow: ellipsis;">
                                            &nbsp; {{ data.label }}
                                        </div>    
                                    </el-link>
                                </span>
                            </template>
                        </el-cascader-panel>
                </el-form-item>
                </el-col>
                <el-col :xl="10" :span="24" v-if="showTiedot.length > 0">
                    <h3>Tarkenna nimikkeiden haku termejä</h3>
                    <template v-for="(selectedNimike, i) in searchForm.nimike" :key="selectedNimike.id">
                        <template v-if="show(selectedNimike.id) && searchForm.nimike">
                            <ComHakuSuunnittelutiedotOminaisuudet :show="show(selectedNimike.id)" v-model="searchForm.nimike[i]" />
                            <el-form-item>
                                <ComCancelButton @click="cascaderFocus(selectedNimike.id)">Peruuta</ComCancelButton>
                            </el-form-item>
                        </template>
                    </template>
                </el-col>
            </template>
        </el-row>
    </el-form>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue';
import { useHakuComponent } from './haku';
import { setNodes, useNimikeKategoria } from '@/methods/nimikkeetComposables';
import ComHakuSuunnittelutiedotOminaisuudet from './ComHakuSuunnittelutiedotOminaisuudet.vue';
import ComNimikeCascader from '@/components/common/ComNimikeCascader.vue';
import ComSearchButton from '@/components/common/buttons/ComSearchButton.vue';
import { ElMessage } from 'element-plus';
import { Edit } from '@element-plus/icons-vue'
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';

const { searchForm, selectedNodes, isSelected, showHeading } = useHakuComponent()
const suunnittelutiedot = computed(() => searchForm.value.nimike)
const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
const showTiedot = ref<number[]>([])
const showHaku = ref(false)
const nodes = computed(() => setNodes(nimikeKategoriat.value ?? [])) 
const hakuModel = ref<[number, number, number] | undefined>(undefined)

const hakuSelectionChange = () => {
    if(hakuModel.value === undefined)  {
        return
    }
    if(selectedNodes.value.find(s => s[2] === hakuModel.value[2])) {
        ElMessage.info("Nimike on jo valittu")
    } else { 
        ElMessage.success("Nimike valittu")

        selectedNodes.value.push(hakuModel.value)
    }

    setTimeout(() => {
        hakuModel.value = [0, 0, 0]
    }, 2000)
}
const isSelectedNimike = (id : number) => {
    return showTiedot.value?.find(s => s === id)
}
const removeFromSelected = (id : number) => {
    showTiedot.value = showTiedot.value.filter(s => s !== id)
}
const cascaderFocus = (id: number | undefined) => {
    if(id === undefined) {
        return
    }
    if(!isSelectedNimike(id)) {
        showTiedot.value.push(id)
    }
    else {
        removeFromSelected(id)
        searchForm.value.nimike?.find((n, i) => {
            if(n.id === id && searchForm.value.nimike) {
                searchForm.value.nimike[i] = { id: id }
            }
        })
    }
}
const show = (id : number | undefined) => {
    return showTiedot.value?.findIndex(s => s === id) !== -1
}

watch(() => selectedNodes.value.map(node => node[2]), (newIds, oldIds) => {
    const removedIds = oldIds.filter(id => !newIds.includes(id))
    const addedIds = newIds.filter(id => !oldIds.includes(id))

    removedIds.forEach(id => {
        removeFromSelected(id);
        searchForm.value.nimike = searchForm.value.nimike?.filter(n => n.id !== id) || []
    })

    addedIds.forEach(id => {
        if (!searchForm.value.nimike?.find(n => n.id === id)) {
            searchForm.value.nimike?.push({ id })
        }
    })

    if (newIds.length === 0) {
        showTiedot.value = []
    }
})

</script>