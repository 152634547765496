import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { KalustepakettiResponse } from '@/api/models/kalustepaketti-response';
import { useKalustepaketit } from '@/methods/kalustepaketitComposables';
import { setNodes, useNimikeKategoria, filter } from '@/methods/nimikkeetComposables';
import { CascaderOption } from 'element-plus';
import { ref, toRef, watch, watchEffect } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeCascader',
  props: /*@__PURE__*/_mergeModels({
    kalustepaketit: {
        type: Boolean,
        default: false
    },
    hanke: {
        type: Number,
        required: false
    },
}, {
    "modelValue": {
    type: Array,
    required: false
},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['cascaderChange'], ["update:modelValue"]),
  setup(__props) {

const props = __props

const model = _useModel(__props, "modelValue")

const { kalustepaketit } = useKalustepaketit(toRef(props.hanke))
const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
const nodes = ref<CascaderOption[]>([] as CascaderOption[])

watch(nimikeKategoriat, (value) => {
    if(value && value.length > 0) {
        nodes.value = setNodes(nimikeKategoriat.value ?? [])
    }
},{ immediate: true })
watch(kalustepaketit, (value) => {
    if(!props.kalustepaketit) {
        return
    }
    if(value && value.length > 0) {
        const k =  value.map((k: KalustepakettiResponse) => {
            return { 
                    value: k.id,
                    label: k.nimi,
                }
        })

        nodes.value.push({
            value: "kalustepaketit",
            label: "Kalustepaketit",
            children: k,
        })
    }
},{ immediate: true })
watchEffect(() => {
    if(!props.kalustepaketit) {
        return
    }
    if(nodes.value && nodes.value.length > 0 && kalustepaketit.value) {
        nodes.value = nodes.value.sort((a, b) => {
            if(a.label && b.label) {
                return a.label.localeCompare(b.label) 
            } else {
                return 0
            }
        })
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_cascader = _resolveComponent("el-cascader")!

  return (_openBlock(), _createBlock(_component_el_cascader, {
    filterable: "",
    clearable: "",
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cascaderChange'))),
    options: nodes.value,
    placeholder: "Hae kirjoittamalla",
    "filter-method": _unref(filter),
    size: "small",
    modelValue: model.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
    style: {"width":"100%"}
  }, null, 8, ["options", "filter-method", "modelValue"]))
}
}

})