import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { style: {"overflow":"hidden","text-overflow":"ellipsis"} }

import { computed, ref, toRef, watch } from 'vue';
import { useHakuComponent } from './haku';
import { setNodes, useNimikeKategoria } from '@/methods/nimikkeetComposables';
import ComHakuSuunnittelutiedotOminaisuudet from './ComHakuSuunnittelutiedotOminaisuudet.vue';
import ComNimikeCascader from '@/components/common/ComNimikeCascader.vue';
import ComSearchButton from '@/components/common/buttons/ComSearchButton.vue';
import { ElMessage } from 'element-plus';
import { Edit } from '@element-plus/icons-vue'
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHakuSuunnittelutiedot',
  setup(__props) {

const { searchForm, selectedNodes, isSelected, showHeading } = useHakuComponent()
const suunnittelutiedot = computed(() => searchForm.value.nimike)
const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
const showTiedot = ref<number[]>([])
const showHaku = ref(false)
const nodes = computed(() => setNodes(nimikeKategoriat.value ?? [])) 
const hakuModel = ref<[number, number, number] | undefined>(undefined)

const hakuSelectionChange = () => {
    if(hakuModel.value === undefined)  {
        return
    }
    if(selectedNodes.value.find(s => s[2] === hakuModel.value[2])) {
        ElMessage.info("Nimike on jo valittu")
    } else { 
        ElMessage.success("Nimike valittu")

        selectedNodes.value.push(hakuModel.value)
    }

    setTimeout(() => {
        hakuModel.value = [0, 0, 0]
    }, 2000)
}
const isSelectedNimike = (id : number) => {
    return showTiedot.value?.find(s => s === id)
}
const removeFromSelected = (id : number) => {
    showTiedot.value = showTiedot.value.filter(s => s !== id)
}
const cascaderFocus = (id: number | undefined) => {
    if(id === undefined) {
        return
    }
    if(!isSelectedNimike(id)) {
        showTiedot.value.push(id)
    }
    else {
        removeFromSelected(id)
        searchForm.value.nimike?.find((n, i) => {
            if(n.id === id && searchForm.value.nimike) {
                searchForm.value.nimike[i] = { id: id }
            }
        })
    }
}
const show = (id : number | undefined) => {
    return showTiedot.value?.findIndex(s => s === id) !== -1
}

watch(() => selectedNodes.value.map(node => node[2]), (newIds, oldIds) => {
    const removedIds = oldIds.filter(id => !newIds.includes(id))
    const addedIds = newIds.filter(id => !oldIds.includes(id))

    removedIds.forEach(id => {
        removeFromSelected(id);
        searchForm.value.nimike = searchForm.value.nimike?.filter(n => n.id !== id) || []
    })

    addedIds.forEach(id => {
        if (!searchForm.value.nimike?.find(n => n.id === id)) {
            searchForm.value.nimike?.push({ id })
        }
    })

    if (newIds.length === 0) {
        showTiedot.value = []
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(showHeading)(1))
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, "Suunnittelutiedot"))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form, {
      model: suunnittelutiedot.value,
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
      ref: "form",
      inline: false,
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            (_unref(isSelected)('nimike'))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_el_col, {
                    xl: 14,
                    span: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_space, null, {
                            default: _withCtx(() => [
                              _createVNode(ComSearchButton, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (showHaku.value = !showHaku.value))
                              }),
                              (showHaku.value)
                                ? (_openBlock(), _createBlock(ComNimikeCascader, {
                                    key: 0,
                                    onCascaderChange: hakuSelectionChange,
                                    modelValue: hakuModel.value,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((hakuModel).value = $event))
                                  }, null, 8, ["modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_cascader_panel, {
                            options: nodes.value,
                            props: {
                            disabled: false,
                            multiple: true,
                        },
                            size: "small",
                            modelValue: _unref(selectedNodes),
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(selectedNodes) ? (selectedNodes).value = $event : null)),
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(({ node, data }) => [
                              (!node.isLeaf || !node.checked)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.label), 1))
                                : (node.isLeaf && node.checked)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                      _createVNode(_component_el_link, {
                                        icon: _unref(Edit),
                                        onClick: ($event: any) => (cascaderFocus(data.value))
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_4, "   " + _toDisplayString(data.label), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["icon", "onClick"])
                                    ]))
                                  : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["options", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (showTiedot.value.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_col, {
                        key: 0,
                        xl: 10,
                        span: 24
                      }, {
                        default: _withCtx(() => [
                          _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Tarkenna nimikkeiden haku termejä", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchForm).nimike, (selectedNimike, i) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: selectedNimike.id
                            }, [
                              (show(selectedNimike.id) && _unref(searchForm).nimike)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createVNode(ComHakuSuunnittelutiedotOminaisuudet, {
                                      show: show(selectedNimike.id),
                                      modelValue: _unref(searchForm).nimike[i],
                                      "onUpdate:modelValue": ($event: any) => ((_unref(searchForm).nimike[i]) = $event)
                                    }, null, 8, ["show", "modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_el_form_item, null, {
                                      default: _withCtx(() => [
                                        _createVNode(ComCancelButton, {
                                          onClick: ($event: any) => (cascaderFocus(selectedNimike.id))
                                        }, {
                                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                                            _createTextVNode("Peruuta")
                                          ])),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ], 64))
}
}

})