import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Search } from "@element-plus/icons-vue"
import ComTooltip from "../ComTooltip.vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'ComSearchButton',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    type: "success",
    size: "small",
    circle: ""
  }, {
    default: _withCtx(() => [
      _createVNode(ComTooltip, { content: "Hae" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_unref(Search))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})